// utils/firebaseErrorMessages.js
const firebaseErrorMessages = {
    'auth/wrong-password': 'A palavra-passe está incorreta.',
  'auth/user-not-found': 'Não encontramos uma conta com este email.',
  'auth/invalid-email': 'O email informado não é válido.',
  'auth/email-already-in-use': 'Este email já está em uso.',
  'auth/weak-password': 'A palavra-passe deve ter pelo menos 6 caracteres.',
  'auth/network-request-failed': 'Falha na conexão. Verifique sua internet e tente novamente.',
  'auth/too-many-requests': 'Muitas tentativas de login. Por favor, tente novamente mais tarde.',
  'auth/user-disabled': 'Esta conta foi desativada. Entre em contato com o suporte para mais informações.',
  'auth/operation-not-allowed': 'Este método de login não está habilitado. Entre em contato com o suporte.',
  'auth/requires-recent-login': 'Por favor, faça login novamente para confirmar sua identidade.',
  'auth/invalid-credential': 'As credenciais fornecidas são inválidas ou expiraram.',
  'auth/invalid-verification-code': 'O código de verificação fornecido é inválido.',
  'auth/invalid-verification-id': 'O ID de verificação fornecido é inválido.',
  'auth/missing-verification-code': 'O código de verificação está ausente.',
  'auth/missing-verification-id': 'O ID de verificação está ausente.',
  'auth/account-exists-with-different-credential': 'Uma conta com este email já existe com credenciais diferentes.',
  'auth/invalid-password': 'A palavra-passe fornecida é inválida.',
  'auth/invalid-phone-number': 'O número de telefone fornecido é inválido.',
  'auth/missing-phone-number': 'O número de telefone está ausente.',
  'auth/invalid-app-credential': 'As credenciais do aplicativo são inválidas.',
  'auth/invalid-app-id': 'O ID do aplicativo é inválido.',
  'auth/invalid-user-token': 'O token do usuário é inválido ou expirou.',
  'auth/user-token-expired': 'O token do usuário expirou. Faça login novamente.',
  'auth/web-storage-unsupported': 'Este navegador não suporta armazenamento web necessário para a autenticação.',
  'auth/invalid-tenant-id': 'O ID do inquilino fornecido é inválido.',
  'auth/multi-factor-auth-required': 'Autenticação multifator é necessária. Por favor, conclua todas as etapas de verificação.',
  'auth/admin-restricted-operation': 'Esta operação é restrita a administradores.',
  'auth/invalid-api-key': 'A chave da API fornecida é inválida.',
  'auth/invalid-user-token': 'O token do usuário é inválido. Faça login novamente.',
  'auth/invalid-custom-token': 'O token personalizado fornecido é inválido.',
  'auth/custom-token-mismatch': 'O token personalizado não corresponde ao esperado.',
  'auth/invalid-continue-uri': 'O URI de continuação fornecido é inválido.',
  'auth/missing-continue-uri': 'O URI de continuação está ausente.',
  'auth/unauthorized-continue-uri': 'O URI de continuação não é autorizado.',
  'auth/invalid-dynamic-link-domain': 'O domínio do link dinâmico fornecido é inválido.',
  'auth/argument-error': 'Um argumento inválido foi fornecido a um método de autenticação.',
  'auth/invalid-persistence-type': 'O tipo de persistência fornecido é inválido.',
  'auth/unsupported-persistence-type': 'O tipo de persistência fornecido não é suportado.',
  'auth/invalid-credential': 'As credenciais fornecidas são inválidas.',
  'auth/wrong-password': 'A palavra-passe está incorreta.',
  'auth/invalid-email': 'O email informado não é válido.',
  'auth/user-not-found': 'Não encontramos uma conta com este email.',
  'auth/user-disabled': 'Esta conta foi desativada. Entre em contato com o suporte para mais informações.',
  'auth/email-already-in-use': 'Este email já está em uso.',
  'auth/operation-not-allowed': 'Este método de login não está habilitado. Entre em contato com o suporte.',
  'auth/weak-password': 'A palavra-passe deve ter pelo menos 6 caracteres.',
  'auth/requires-recent-login': 'Por favor, faça login novamente para confirmar sua identidade.',
  'auth/credential-already-in-use': 'Estas credenciais já estão associadas a outra conta.',
  'auth/invalid-verification-code': 'O código de verificação fornecido é inválido.',
  'auth/invalid-verification-id': 'O ID de verificação fornecido é inválido.',
  'auth/missing-verification-code': 'O código de verificação está ausente.',
  'auth/missing-verification-id': 'O ID de verificação está ausente.',
  'auth/invalid-phone-number': 'O número de telefone fornecido é inválido.',
  'auth/missing-phone-number': 'O número de telefone está ausente.',
  'auth/invalid-app-credential': 'As credenciais do aplicativo são inválidas.',
  'auth/invalid-app-id': 'O ID do aplicativo é inválido.',
  'auth/invalid-user-token': 'O token do usuário é inválido ou expirou.',
  'auth/user-token-expired': 'O token do usuário expirou. Faça login novamente.',
  'auth/web-storage-unsupported': 'Este navegador não suporta armazenamento web necessário para a autenticação.',
  'auth/invalid-tenant-id': 'O ID do inquilino fornecido é inválido.',
  'auth/multi-factor-auth-required': 'Autenticação multifator é necessária. Por favor, conclua todas as etapas de verificação.',
  'auth/admin-restricted-operation': 'Esta operação é restrita a administradores.',
  'auth/invalid-api-key': 'A chave da API fornecida é inválida.',
  'auth/invalid-user-token': 'O token do usuário é inválido. Faça login novamente.',
  'auth/invalid-custom-token': 'O token personalizado fornecido é inválido.',
  'auth/custom-token-mismatch': 'O token personalizado não corresponde ao esperado.',
  'auth/invalid-continue-uri': 'O URI de continuação fornecido é inválido.',
  'auth/missing-continue-uri': 'O URI de continuação está ausente.',
  'auth/unauthorized-continue-uri': 'O URI de continuação não é autorizado.',
    default: 'Ocorreu um erro inesperado. Por favor, tente novamente.',
  };
  
  export const getFirebaseErrorMessage = (errorCode) => {
    return firebaseErrorMessages[errorCode] || firebaseErrorMessages.default;
  };
  